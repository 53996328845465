import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ContentMessage } from '@goalmate/typings';

export const ContentMessageActions = createActionGroup({
  source: 'ContentMessage/API',
  events: {
    'Load ContentMessages': props<{ userId: string }>(),
    'Load ContentMessages Next Page': props<{
      userId: string;
      lastMessageId: string;
    }>(),
    'Load ContentMessages Success': props<{
      contentMessages: ContentMessage[];
    }>(),
    'Load ContentMessages Error': props<{
      error: string;
    }>(),
    'Add ContentMessage': props<{ contentMessage: ContentMessage }>(),
    'Upsert ContentMessage': props<{ contentMessage: ContentMessage }>(),
    'Add ContentMessages': props<{ contentMessages: ContentMessage[] }>(),
    'Upsert ContentMessages': props<{ contentMessages: ContentMessage[] }>(),
    'Update ContentMessage': props<{
      contentMessage: Update<ContentMessage>;
    }>(),
    'Update ContentMessages': props<{
      contentMessages: Update<ContentMessage>[];
    }>(),
    'Update Pagination': props<{ hasNextPage: boolean }>(),
    'Delete ContentMessage': props<{ id: string }>(),
    'Delete ContentMessages': props<{ ids: string[] }>(),
    'Clear ContentMessages': emptyProps(),
  },
});
